import _debounce from 'lodash/debounce';
import FavoriteBtn from 'src/components/FavoriteButton/FavoriteBtn';
import TagSymbol from 'src/components/Molecules/Symbols/Symbol';
import { isGuestUser } from 'src/helper/helperMethods';
import { ItemDetails } from 'src/models/item.model';
import { useAppSelector } from 'src/redux/store/store';
interface ItemDescriptionProps {
  item: ItemDetails;
  isItemActive: boolean;
  locationId?: number;
}

const index = ({ item, isItemActive, locationId }: ItemDescriptionProps) => {
  const user = useAppSelector((state) => state.user);
  return (
    <div className={`${item.is_box_lunch ? 'pt-md-0 pt-3' : 'pt-0'}`}>
      <div className="d-flex justify-content-between">
        <h2 className="f-s30 f-sm-s18 font-Visby-cf fw-bold clr-dark-gray f-sm-s18  pt-md-0 ">
          {item?.name}
        </h2>
        {!item.is_create_your_own && !isGuestUser(user) && (
          <FavoriteBtn item={item} disableItem={!isItemActive} locationId={locationId} />
        )}
      </div>
      <div className="py-1">
        {item?.is_salad_tray ? <TagSymbol tags={item.tags} /> : null}
      </div>
      <p className="f-s16">{item?.description}</p>
    </div>
  );
};

export default index;
