import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router';
import { setLocation } from 'src/Features/Location/redux/slice';
import { GUEST_USER, ROUTE_MENU, ROUTE_OTHER_ITEM, ROUTE_START_CATERING_ORDER } from 'src/constants';
import { handleIsLiveLocationRedirection } from 'src/helper/checkout/LocationHelper';
import {
  getVisitorId,
  isGuestUser,
  validateAddressCount,
  validateCount,
} from 'src/helper/helperMethods';
import { ISelectedAddress } from 'src/models/forms.model';
import { IItemPathToRoute } from 'src/models/item.model';
import {
  INearestLocationQueryParams,
  LocationModel,
} from 'src/models/location.model';
import { IGetCartPayload } from 'src/models/order.model';
import useProfile from 'src/react-query-hooks/useProfile';
import {
  updateIsOrderTypeSelected,
  updateOrderDetails,
  updateOrderType,
} from 'src/redux/slices/cartSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store/store';
import { locationMenuService } from 'src/services';
import { selectDeliveryAddressSchema } from 'src/validationSchemas/selectDeliveryAddressSchema';

import Button from '../Button/Button';
import FormField from '../FormFields/FormField';

import VisitorDeliveryAddress from './VisitorDeliveryAddress';
import { bugSnagNotifyAPIError } from 'src/helper/bugSnagHelper';
import UserAddressDropdown from './DeliveryTypeModal/UserAddressDropdown';
import ConfirmLocationChangeModal from './ItemPickup/ConfirmLocationChangeModal';
import { pushSEOSelectLocationEvent } from 'src/helper/scripts/seoScript';
interface IItemDelivery {
  itemPath: IItemPathToRoute;
  shownDeilveryLocation: (isShown) => void;
  closeOpenOrderModal: () => void;
  nearestLocation: (isNearest) => void;
  isNearestStoreAvailable: boolean;
  showItemDeliveryDateTime?: boolean;
  handleShowDeliveryMethodModal?: (boolean) => void;
  saveSelectedOrderType?: string;
  handleSavedOrderType?: () => void;
  handleOrderType?: (addressType: string) => void;
  isItMobile: boolean;
  isCheckout: boolean;
  routeTo?: string;
  initiator?: string;
}
function ItemDelivery({
  shownDeilveryLocation,
  closeOpenOrderModal,
  nearestLocation,
  isNearestStoreAvailable,
  showItemDeliveryDateTime,
  handleShowDeliveryMethodModal,
  saveSelectedOrderType,
  // handleSavedOrderType,
  handleOrderType,
  isItMobile,
  isCheckout,
  itemPath,
  initiator,
}: IItemDelivery) {
  const queryClient = useQueryClient();
  const location = useLocation();
  const orderTypeDispatch = useAppDispatch();
  const locationDispatch = useAppDispatch();
  const isPrimaryCapacityFormFilled = useAppSelector(
    (state) => state.specificFlowStates.primaryCapacity.formFilled,
  );
  const history = useHistory();
  const [userAddresses, setUserAddresses] = useState([]);
  const [nearestStoreAvailable, setNearestStoreAvailable] =
    useState<LocationModel>(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isFindingNearest, setIsFindingNearest] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const selectedDeliveryAddress = useAppSelector(
    (state) => state.cart?.orderDetails?.delivery_address || '',
  );
  const { user } = useAppSelector((state) => state.user);
  const [newAddedAddressForDelivery, setNewAddedAddressForDelivery] = useState(
    selectedDeliveryAddress?.address_name
      ? {
        value: selectedDeliveryAddress.address_name,
        label: selectedDeliveryAddress.address_name,
        id: selectedDeliveryAddress.id,
        isDefault: selectedDeliveryAddress.is_default,
        street: selectedDeliveryAddress.street_name,
        ...selectedDeliveryAddress,
      }
      : null,
  );
  const isGuestAddressSelected = selectedDeliveryAddress?.isGuestAddress;
  const showNewAddressForm = () => {
    if (isGuestAddressSelected) return false;
    else return isGuestUser(user) || !userAddresses?.length;
  };
  const onCloseConfirmationMoal = () => {
    setShowConfirmationModal(false);
  };
  const [addGuestAddress, setAddGuestAddress] = useState<boolean>(
    showNewAddressForm(),
  );
  const visitor_id = getVisitorId();

  const {
    isLoading,
    isFetching,
    data: userProfileData,
    error,
  } = useProfile(user.id, user.type);

  const checkNearestStoreLocation = async (address) => {
    setIsFindingNearest(true);
    const payload: INearestLocationQueryParams = {
      delivery: true,
      city: address.city,
      state: address.state,
      street: address.street || address?.street_name,
      zipcode: address.zipcode,
      delivery_address:
        user.type === GUEST_USER ? address?.fullAddress : address?.full_address,
    };
    if (isCheckout) payload.is_checkout = 1;
    try {
      const response = await locationMenuService.getNearbyLocations(payload);
      if (response?.data?.data[0]) {
        setNearestStoreAvailable(response.data.data[0]);
        nearestLocation(true);
        setIsFindingNearest(false);
        return response.data.data[0];
      } else {
        setNearestStoreAvailable(null);
        nearestLocation(false);
        setIsFindingNearest(false);
        return null;
      }
    }
    catch (error) {
      const errorMessage = error?.response?.data?.errors?.[0] || error?.message || "Unknown error";
      bugSnagNotifyAPIError("Nearby locations", payload, errorMessage);
    }
  };

  const closeNewAddressForm = () => {
    shownDeilveryLocation(false);
    setAddGuestAddress(false);
  };
  const setAddress = (address) => {
    setSelectedAddress(address);
  };

  const handleLocation = (nearestLoc, deliveryAddress) => {
    if (!nearestLoc.is_live) {
      handleIsLiveLocationRedirection(
        saveSelectedOrderType,
        nearestLoc,
        isItMobile,
      );
    } else {
      orderTypeDispatch(
        updateOrderDetails({ delivery_address: deliveryAddress }),
      );
      orderTypeDispatch(updateIsOrderTypeSelected(true));
      locationDispatch(setLocation(nearestLoc));
      pushSEOSelectLocationEvent(nearestLoc);
      const payload: IGetCartPayload = {
        location_id: nearestLoc.id.toString(),
      };
      if (user.id) payload.customer_id = user.id;
      else payload.visitor_id = visitor_id;
      queryClient.refetchQueries(['get-cart', nearestLoc.id]);
      closeOpenOrderModal();
      if (saveSelectedOrderType) {
        orderTypeDispatch(updateOrderType(saveSelectedOrderType));
      }
      if (itemPath) {
        const goto = isPrimaryCapacityFormFilled
          ? itemPath
          : {
            pathname: ROUTE_START_CATERING_ORDER,
            state: { initiator: initiator, itemPath: itemPath },
          };
        history.push(goto);
      } else if (location.pathname.includes(ROUTE_OTHER_ITEM)) {
        history.push(ROUTE_MENU);
      }
    }
    setShowConfirmationModal(false);
  };

  const handleFormSubmission = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const response = await checkNearestStoreLocation(selectedAddress);
    if (!response) {
      setSubmitting(false);
    } else {
      if (
        location.pathname.includes(ROUTE_OTHER_ITEM)
      ) {
        setShowConfirmationModal(true);
        setSubmitting(false);
      } else {
        if (response && !response.is_live) {
          handleIsLiveLocationRedirection(
            saveSelectedOrderType,
            response,
            isItMobile,
          );
        } else {
          handleLocation(response, selectedAddress);
          setSubmitting(false);
        }
      }
    }
  };

  const selectedDelieveryAddress = (allAddressArray: any) => {
    let address = newAddedAddressForDelivery;
    if (newAddedAddressForDelivery) {
      address = allAddressArray?.find(
        (address) => address?.id === newAddedAddressForDelivery?.id,
      );
    }
    return address;
  };

  useEffect(() => {
    if (!isGuestUser(user)) {
      if (!isFetching && userProfileData?.addresses?.length > 0) {
        setAddGuestAddress(false);
        const allAddressArray = userProfileData.addresses.map((address) => ({
          value: address.address_name,
          label: address.address_name,
          id: address.id,
          isDefault: address.is_default,
          street: address.street_name,
          ...address,
        }));
        setUserAddresses(allAddressArray);
        setSelectedAddress(
          newAddedAddressForDelivery
            ? selectedDelieveryAddress(allAddressArray)
            : allAddressArray.find((address) => address.isDefault == 1),
        );
      }
    } else if (newAddedAddressForDelivery)
      setSelectedAddress(newAddedAddressForDelivery);
  }, [userProfileData, newAddedAddressForDelivery]);

  const initialFormState: ISelectedAddress = {
    selected_address:
      newAddedAddressForDelivery ||
      userAddresses.find((address) => address.isDefault === 1),
  };
  return (
    <div>
      <div className="new_form_design gap-24">
        {
          addGuestAddress ? (
            <VisitorDeliveryAddress
              handleLocation={handleLocation}
              isItMobile={isItMobile}
              handleShowDeliveryMethodModal={handleShowDeliveryMethodModal}
              showItemDeliveryDateTime={showItemDeliveryDateTime}
              checkNearestStoreLocation={checkNearestStoreLocation}
              setAddress={setAddress}
              selectedAddress={selectedAddress}
              saveSelectedOrderType={saveSelectedOrderType}
              handleOrderType={handleOrderType}
              isFindingNearest={isFindingNearest}
              isNearestStoreAvailable={isNearestStoreAvailable}
              setNewAddedAddressForDelivery={setNewAddedAddressForDelivery}
              closeNewAddressForm={closeNewAddressForm}
              userAddresses={userAddresses}
            />
          ) : (
            <UserAddressDropdown
              isGuestAddressSelected={isGuestAddressSelected}
              userAddresses={userAddresses}
              newAddedAddressForDelivery={newAddedAddressForDelivery}
              isNearestStoreAvailable={isNearestStoreAvailable}
              handleFormSubmission={handleFormSubmission}
              setAddress={setAddress}
              checkNearestStoreLocation={checkNearestStoreLocation}
              selectedAddress={selectedAddress}
              setAddGuestAddress={setAddGuestAddress}
              isFindingNearest={isFindingNearest}
            />
          )}
      </div>
      <ConfirmLocationChangeModal
        showModal={showConfirmationModal}
        handleClose={() => onCloseConfirmationMoal()}
        confirmChange={() => handleLocation(nearestStoreAvailable, selectedAddress)}
        loading={false}
        isDeliveryOrderModal={true}
      />
    </div>
  );
}

export default ItemDelivery;
