import { cartItem } from "src/models/cart.model";
import { toFixedNumber } from "src/priceCalculation/helper";

export const isIndexFound = (item, suggestedItem) => {
  let isItemRelatedIdSame = null;
  if (suggestedItem.item_id)
    isItemRelatedIdSame = item.item_id == suggestedItem.item_id;
  else isItemRelatedIdSame = item.category_id == suggestedItem.category_id;
  const isItemRedeemable = !item.reward_id && !suggestedItem.in_cart;
  const isItemRedeemed = item.reward_id === suggestedItem.id;
  const areItemRedeemChecksValid = isItemRedeemable || isItemRedeemed;

  const isItemQuantityEqualTo1 = !(item.quantity > 1);
  const isItemNotRedeemedByPoints = !item.redemption_by_points;
  const isItemSizeSame =
    item.item_size.toLowerCase() == suggestedItem.size.toLowerCase();

  return (
    areItemRedeemChecksValid &&
    isItemQuantityEqualTo1 &&
    isItemNotRedeemedByPoints &&
    isItemRelatedIdSame &&
    isItemSizeSame
  );
};

export const cartSubTotal = (cartItems: cartItem[]) => {
  let itemDiscount = 0;
  let subTotal = 0;
  cartItems.forEach((item: cartItem) => {
    const price = item.gross_price ?? item.display_price
    subTotal += toFixedNumber(price * item.quantity)
  });
  return toFixedNumber(subTotal - itemDiscount)
}