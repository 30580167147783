import { useEffect } from 'react';

const CrawlingMeta = () => {
  useEffect(() => {
    if (import.meta.env.VITE_REACT_APP_DISABLE_CRAWLING === 'true') {
      const robotsMeta = document.createElement('meta');
      robotsMeta.name = 'robots';
      robotsMeta.content = 'none';
      document.head.appendChild(robotsMeta);

      const googlebotMeta = document.createElement('meta');
      googlebotMeta.name = 'googlebot';
      googlebotMeta.content = 'none';
      document.head.appendChild(googlebotMeta);
    }
  }, []);

  return null;
};

export default CrawlingMeta;
