
import { cartItem } from "../../models/cart.model";
import ReactGA from "react-ga4";
import { getUser } from "../helperMethods";
import { toFixedNumber } from "src/priceCalculation/helper";
import { cartSubTotal } from "../cartHelperMethods";

const userId = getUser()?.id || null;

export const getStructuredItem = (item: cartItem) => {
    return {
        'item_id': item?.id,
        'item_name': item?.name,
        'discount': item?.discount_amount || item?.discount || 0,
        'index': item?.index || 0,
        'item_brand': "Cafe Zupas",
        'item_category': item?.category_name?.toString() || item?.category_id?.toString(),
        'item_list_id': item?.category_id,
        'item_list_name': item?.category_name || item?.name?.toString(),
        'location_id': item?.locationId || 15,
        'price': item?.display_price || item?.base_price || item?.price,
        'quantity': item?.quantity || 1
    }
}

export const getStructuredItemList = (cartItems: cartItem[], locationId) => {
    let items =
        cartItems?.map((item: cartItem) => {
            return getStructuredItem({ ...item, locationId })
        })
    return items
}

export const pushSEOViewItemListEvent = (menu, locationId) => {
    ReactGA.event('view_item_list', {
        'user_id': userId || null,
        'ecommerce': {
            'item_list_id': menu?.id,
            'item_list_name': menu?.name,
            'items': getStructuredItemList(
                menu?.items,
                locationId
            )
        },
    })
}

export const pushSEOSelectItemEvent = (item) => {
    ReactGA.event('select_item', {
        'user_id': userId || null,
        'ecommerce': {
            'item_list_id': item?.category_id,
            'item_list_name': item?.category_name,
            'items': getStructuredItem(
                item
            )
        },
    })
}

export const pushSEOAddToCartEvent = (item) => {
    ReactGA.event('add_to_cart', {
        'user_id': userId,
        'ecommerce': {
            'currency': "USD",
            'value': item?.display_price,
            'items': getStructuredItem(
                item
            )
        },
    })
}

export const pushSEOViewItemEvent = (item) => {
    ReactGA.event('view_item', {
        'user_id': userId || null,
        'ecommerce': {
            'item_list_id': item?.category_id,
            'item_list_name': item?.category_name,
            'items': getStructuredItem(
                item
            )
        },
    })
}

export const pushSEOLoginEvent = (user) => {
    ReactGA.event('login', { 'login_method': "email", 'new_user_id': user.id });
}

export const pushSEOSignupEvent = (user) => {
    ReactGA.event('signup', { 'signup_method': 'email', 'new_user_id': user?.id })
}

export const pushSEOAddToWishlistEvent = (item) => {
    ReactGA.event('add_to_wishlist', {
        'user_id': userId,
        'ecommerce': {
            'currency': "USD",
            'value': item?.price,
            'items': [
                getStructuredItem(item as any)
            ]
        }
    })
}

export const pushSEORemoveFromCartEvent = (item) => {
    ReactGA.event('remove_from_cart', {
        'user_id': userId,
        'ecommerce': {
            'currency': "USD",
            'value': item?.display_price,
            'items': [
                getStructuredItem(item as any)
            ]
        }
    })
}

export const pushSEOSelectPromotionEvent = (items, offer, locationId, offerDisplay) => {
    ReactGA.event('select_promotion', {
        'user_id': userId,
        'ecommerce': {
            'currency': "USD",
            "creative_name": offer?.name,
            "creative_slot": offerDisplay,
            "promotion_id": offer?.loyalty_offer_id,
            "promotion_name": offer?.name,
            'items': [
                getStructuredItemList(
                    items,
                    locationId
                )
            ]
        }
    })
}

export const pushSEOViewPromotionEvent = (offers, locationId, offerDisplay) => {
    ReactGA.event('view_promotion', {
        'user_id': userId,
        'ecommerce': {
            'currency': "USD",
            'items': offers?.map((offer) => {
                return {
                    "creative_name": offer?.name,
                    "creative_slot": offerDisplay,
                    "promotion_id": offer?.loyalty_offer_id,
                    "promotion_name": offer?.name,
                }
            }
            )
        }
    })
}

export const pushSEOSelectLocationEvent = (location) => {
    ReactGA.event(
        'select_location',
        {
            'location_name': location?.name,
            'location_id': location?.id
        })
}

export const pushSEOPurchaseEvent = (order: any) => {
    ReactGA.event("purchase", {
        user_id: userId,
        ecommerce: {
            transaction_id: order?.id,
            value: toFixedNumber(Number(order?.total_price) + Number(order?.tip)),
            tax: order?.tax,
            shipping: order?.delivery_fee,
            currency: "USD",
            coupon: order?.discount,
            items:
                getStructuredItemList(
                    order?.items,
                    order?.location_id
                )
        },
    })
}

export const pushSEORefundEvent = (order: any) => {
    ReactGA.event("refund", {
        user_id: userId,
        ecommerce: {
            transaction_id: order?.id,
            value: toFixedNumber(Number(order?.total_price) + Number(order?.tip)),
            tax: order?.tax,
            shipping: order?.delivery_fee,
            currency: "USD",
            coupon: order?.discount,
            items:
                getStructuredItemList(
                    order?.items,
                    order?.location_id
                )
        }
    })
}

export const pushSEOViewCartEvent = (items: any, locationId) => {
    ReactGA.event("view_cart", {
        user_id: userId,
        ecommerce: {
            currency: "USD",
            value: cartSubTotal(items),
            items:
                getStructuredItemList(
                    items,
                    locationId
                )
        }
    })
}

export const pushSEOBeginCheckoutEvent = (items: any, locationId) => {
    ReactGA.event("begin_checkout", {
        user_id: userId,
        ecommerce: {
            currency: "USD",
            value: cartSubTotal(items),
            items:
                getStructuredItemList(
                    items,
                    locationId
                )
        }
    })
}